import FormValidator from './form-validator';
import LabelAnimation from './label-animation';
import PasswordValidationBinder from './password-validation-binder';
import { recordPageView } from './analytics';
import MaskingHelperModule from './masking-helper';

class ResetPasswordModule {
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-primary');

    const formValidator = new FormValidator(form, submitButton);
    const passwordField = document.querySelector('#new-password');
    const confirmPasswordField = document.querySelector('#confirm-new-password');

    const passwordLengthChecklistItem = document.querySelector('#password-length-requirement');
    const passwordCaseChecklistItem = document.querySelector('#password-case-requirement');
    const passwordNumberChecklistItem = document.querySelector('#password-number-requirement');

    const passwordValidationBinder = new PasswordValidationBinder(
      formValidator,
      passwordField,
      confirmPasswordField,
      passwordLengthChecklistItem,
      passwordCaseChecklistItem,
      passwordNumberChecklistItem);

    passwordValidationBinder.bindValidators();

    formValidator.setup();

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();

    const passwordMaskingButton = document.querySelector('#new-password-masking-button');
    const confirmPasswordMaskingButton = document.querySelector('#confirm-new-password-masking-button');
    const maskingHelperModule = new MaskingHelperModule();
    maskingHelperModule.execute(passwordMaskingButton, passwordField);
    maskingHelperModule.execute(confirmPasswordMaskingButton, confirmPasswordField);

    recordPageView({
      pagefunction: 'forgot_password',
      pagename: 'create_new_password'
    });
  }
}

window.IGNITE['ResetPasswordModule'] = ResetPasswordModule;
export default ResetPasswordModule;
