/**
 * Initializes the simility context with base constants, standard
 * across all login pages
 */
export const initialize = (sessionId, customerId) => {
  if (window.SimilityScript) {
    window.similityContext = {
      'customer_id': customerId,
      'session_id': sessionId,
      'zone': 'us'
    };
  }
};

/**
 * Records the page view for simility with the page-specific info
 * @param {string} eventTypes - can be multiple comma separated values
 */
export const recordPageView = (eventTypes) => {
  if (window.SimilityScript) {
    window.similityContext.event_types = eventTypes;
    const ss = new window.SimilityScript(window.similityContext);
    ss.execute();
  }
};

window.IGNITE['SimilityModule'] = { initialize };
