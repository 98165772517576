class PasswordValidators {
  static passwordEmptyValidation (passwordField) {
    const valid = (passwordField.value.length !== 0);
    return { valid, customValidationMessage: 'Password must not be empty.' };
  }

  static passwordLengthValidation (passwordField) {
    const valid = (passwordField.value.length >= 7 && passwordField.value.length <= 20);
    return { valid, customValidationMessage: 'Password must contain between 7 and 20 characters.' };
  }

  static passwordCaseValidation (passwordField) {
    const casePattern = new RegExp('^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})');
    const valid = (casePattern.test(passwordField.value));
    return { valid, customValidationMessage: 'Password must include both upper and lower case letters.' };
  }

  static passwordNumberValidation (passwordField) {
    const numberPattern = new RegExp('^(?=(.*[0-9]){2,})');
    const valid = (numberPattern.test(passwordField.value));
    return { valid, customValidationMessage: 'Password must include at least 2 numbers.' };
  }

  static passwordEqualityValidation (passwordField, confirmPasswordField) {
    const valid = (passwordField.value === confirmPasswordField.value);
    return { valid, customValidationMessage: 'Passwords do not match.' };
  }

  static passwordSpecialCharactersValidation (passwordField) {
    const invalidCharactersPattern = new RegExp('[^A-Za-z0-9!#$*+-.:;=?@^_`|~,]');
    const valid = (!invalidCharactersPattern.test(passwordField.value) && passwordField.value.length !== 0);
    return { valid, customValidationMessage: 'Special characters allowed are: !#$*+-.:;=?@^_`|~,' };
  }
}

export default PasswordValidators;
