// import Handlebars from 'hbs';
import FormValidator from './form-validator';
import LabelAnimation from './label-animation';
import { getNodeType, recordClickEvent, recordPageView, AnalyticsConstants } from './analytics';
import { recordPageView as recordSimilityPageView } from './simility';
import MaskingHelperModule from './masking-helper';

class LoginModule {
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-login');
    const formValidator = new FormValidator(form, submitButton);
    formValidator.setup();

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();

    const maskingButton = document.querySelector('.masking-button');
    const passwordField = document.getElementsByClassName('js-password')[0];
    new MaskingHelperModule().execute(maskingButton, passwordField);

    const flashMessage = document.getElementById('flash-message');
    const pageName = flashMessage ? flashMessage.getAttribute(AnalyticsConstants.ANALYTICS_PAGE_NAME_KEY) : 'start';
    const pageSubFunction = pageName === 'start_max_unconfirmed_attempts' ? 'otp' : '';

    recordPageView({
      pagefunction: 'login',
      pagesubfunction: pageSubFunction,
      pagename: pageName
    });

    recordSimilityPageView('user_login');

    // Setup dynamic click event for analytics
    form.addEventListener('submit', () => {
      const type = getNodeType(submitButton);
      const usernameCheckbox = document.getElementById('login-checkbox');
      const title = usernameCheckbox && usernameCheckbox.checked ? 'login_remember_me' : 'login';
      recordClickEvent({ type, title });
    });
  }
}

window.IGNITE['LoginModule'] = LoginModule;
export default LoginModule;
