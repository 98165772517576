var url = document.getElementById('surfly').getAttribute('data-url');
var widgetKey = document.getElementById('surfly').getAttribute('data-widget');
(function (s, u, r, f, l, y) {
  s[f] = s[f] || { init: function () { s[f].q = arguments; } };
  l = u.createElement(r); y = u.getElementsByTagName(r)[0]; l.async = 1;
  l.src = url + '/surfly.js'; y.parentNode.insertBefore(l, y);
})(window, document, 'script', 'Surfly');

var settings = {
  widget_key: widgetKey
};
Surfly.init(settings, function (initResult) {
  if (initResult.success) {
    console.log('All good for happy cobrowsing!');
  } else {
    console.log('Your browser lacks features required by Surfly');
  }
});
