class OAuthConsentModule {
  execute () {
    const form = document.querySelector('form');

    const denyButton = form.querySelector('.btn-secondary');
    const userApproval = form.querySelector('input[name="user_oauth_approval"]');

    denyButton.addEventListener('click', event => {
      event.preventDefault();
      userApproval.value = 'false';

      form.submit();
    });
  }
}

window.IGNITE['OAuthConsentModule'] = OAuthConsentModule;
export default OAuthConsentModule;
