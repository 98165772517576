import PasswordValidators from './password-validators';
import FormValidator from './form-validator';

class PasswordValidationBinder {
  constructor (formValidator, passwordField, confirmPasswordField, lengthCheck, caseCheck, numberCheck) {
    this.formValidator = formValidator;
    this.fields = {
      passwordField,
      confirmPasswordField,
      lengthCheck,
      caseCheck,
      numberCheck
    };
  }

  bindValidators () {
    const passwordFieldValidators = [
      () => PasswordValidators.passwordEmptyValidation(this.fields.passwordField),
      () => PasswordValidators.passwordLengthValidation(this.fields.passwordField),
      () => PasswordValidators.passwordCaseValidation(this.fields.passwordField),
      () => PasswordValidators.passwordNumberValidation(this.fields.passwordField),
      () => PasswordValidators.passwordSpecialCharactersValidation(this.fields.passwordField)
    ];

    const confirmPasswordFieldValidators = [
      () => PasswordValidators.passwordEmptyValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordLengthValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordCaseValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordNumberValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordSpecialCharactersValidation(this.fields.confirmPasswordField),
      () => PasswordValidators.passwordEqualityValidation(this.fields.passwordField, this.fields.confirmPasswordField)
    ];

    this.formValidator.registerCustomInputValidations(this.fields.passwordField, passwordFieldValidators);

    this.formValidator.registerCustomInputValidations(this.fields.confirmPasswordField, confirmPasswordFieldValidators);

    let prevMatchCount = 0;
    this.fields.passwordField.addEventListener('input', () => {
      const lengthCheck = PasswordValidators.passwordLengthValidation(this.fields.passwordField).valid;
      const caseCheck = PasswordValidators.passwordCaseValidation(this.fields.passwordField).valid;
      const numberCheck = PasswordValidators.passwordNumberValidation(this.fields.passwordField).valid;
      const specialCharCheck = PasswordValidators.passwordSpecialCharactersValidation(this.fields.passwordField).valid;

      if (this.fields.lengthCheck) {
        FormValidator.toggleCheckmarkValidityForPwd(this.fields.lengthCheck, PasswordValidators.passwordLengthValidation(this.fields.passwordField).valid);
      }

      if (this.fields.caseCheck) {
        FormValidator.toggleCheckmarkValidityForPwd(this.fields.caseCheck, PasswordValidators.passwordCaseValidation(this.fields.passwordField).valid);
      }

      if (this.fields.numberCheck) {
        FormValidator.toggleCheckmarkValidityForPwd(this.fields.numberCheck, PasswordValidators.passwordNumberValidation(this.fields.passwordField).valid);
      }
      if (lengthCheck && caseCheck && numberCheck && specialCharCheck) {
        this.fields.passwordField.setAttribute('aria-invalid', 'false');
        this.fields.passwordField.setAttribute('aria-describedby', 'password-requirements');
      } else {
        this.fields.passwordField.setAttribute('aria-invalid', 'true');
        this.fields.passwordField.setAttribute('aria-describedby', 'pwdErr');
      }

      if (this.fields.confirmPasswordField && this.fields.confirmPasswordField.value !== '') {
        const response = PasswordValidators.passwordEqualityValidation(this.fields.passwordField, this.fields.confirmPasswordField);
        if (response.valid === true) {
          this.fields.confirmPasswordField.setAttribute('aria-invalid', 'false');
          this.fields.confirmPasswordField.setAttribute('aria-describedby', 'password-requirements-describe');
          document.getElementById('confpwdErr').innerHTML = '';
          document.getElementById('confirm-password-field').classList.remove('invalid');
          this.fields.confirmPasswordField.setCustomValidity('');
        } else {
          this.fields.confirmPasswordField.setAttribute('aria-invalid', 'true');
          this.fields.confirmPasswordField.setAttribute('aria-describedby', 'confpwdErr');
          document.getElementById('confpwdErr').innerHTML = response.customValidationMessage;
          this.fields.confirmPasswordField.setCustomValidity(response.customValidationMessage);
          document.getElementById('confirm-password-field').classList.add('invalid');
        }
      }

      const matchedCount = document.getElementById('password-requirements').getElementsByClassName('valid').length;
      if (matchedCount > 0 && matchedCount !== prevMatchCount) {
        updateAlert(matchedCount + ' of 3 requirements met');
        prevMatchCount = matchedCount;
      } else if (matchedCount === 0) {
        updateAlert('');
        prevMatchCount = 0;
      }
    });

    this.fields.confirmPasswordField.addEventListener('input', () => {
      const lengthValid = PasswordValidators.passwordLengthValidation(this.fields.confirmPasswordField).valid;
      const caseValid = PasswordValidators.passwordCaseValidation(this.fields.confirmPasswordField).valid;
      const numberValid = PasswordValidators.passwordNumberValidation(this.fields.confirmPasswordField).valid;
      const matchValid = PasswordValidators.passwordEqualityValidation(this.fields.passwordField, this.fields.confirmPasswordField).valid;

      if (lengthValid && caseValid && numberValid && matchValid) {
        this.fields.confirmPasswordField.setAttribute('aria-invalid', 'false');
        this.fields.confirmPasswordField.setAttribute('aria-describedby', 'password-requirements-describe');
      } else {
        this.fields.confirmPasswordField.setAttribute('aria-invalid', 'true');
        this.fields.confirmPasswordField.setAttribute('aria-describedby', 'confpwdErr');
      }
    });
  }
}

function updateAlert (msg) {
  const oldAlert = document.getElementById('alert');
  const content = document.createTextNode(msg);
  if (oldAlert.hasChildNodes()) {
    const existingChild = oldAlert.firstChild;
    oldAlert.replaceChild(content, existingChild);
  } else {
    oldAlert.appendChild(content);
  }
}

export default PasswordValidationBinder;
