class BioCatchHelperModule {
  static handleCDApiAttribute (sessionId, context, enabled) {
    console.log('Is BioCatch update Page Context Feature enabled : ' + enabled);
    if (enabled) {
      console.log('BioCatch update Page Context started for ' + context);
      try {
        window.cdApi.setCustomerSessionId(sessionId);
        window.cdApi.setCustomerBrand('SYFBANK');
        window.cdApi.changeContext(context);
      } catch (e) {
        console.log('Error occurred. Unable to set CDApi Attribute');
      }
    }
  }
}

window.IGNITE['BioCatchHelperModule'] = BioCatchHelperModule;
