class FormHelper {
  focusableInputs (collection) {
    return Array.from(collection)
      .filter(element => this.isFocusableInput(element));
  }

  isFocusableInput (input) {
    return (input.nodeName === 'INPUT' && input.type !== 'hidden') ||
      input.nodeName === 'SELECT' ||
      input.nodeName === 'TEXTAREA';
  }
}

export default new FormHelper();
