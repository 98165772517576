import dialogPolyfill from './dialog-polyfill';

class SessionRefreshModule {
  /**
   * - Polyfills the session dialog component
   * - Adds an event listener to the dialog, which reloads the window
   */
  constructor (sessionDuration, warningDuration, logoutUrl) {
    // The duration of time in milliseconds that a session is active
    this.sessionDuration = sessionDuration;
    // A duration of time in milliseconds (e.g. 1 minute before expiration) when the client should be notified of session expiration
    this.warningDuration = warningDuration;
    // The url to GET to logout
    this.logoutUrl = logoutUrl;
    // A reference to the dialog box
    this.dialog = document.getElementById('session-dialog');
    // The instant in time this class was created
    this.startTime = Date.now();
    // The frequency to check session expiration in millis
    this._checkSessionFrequency = 2000;

    dialogPolyfill.registerDialog(this.dialog);

    const button = this.dialog.querySelector('.btn-primary');
    button.addEventListener('click', () => {
      // TODO call a refresh endpoint instead, which preserves form state
      window.location.reload();
    });
  }

  /**
   * Loops continuously, checking if the session warning modal should show based on current time and sessionDuration.
   * Logs the user out if inactive for the entire duration.
   */
  checkSessionLoop () {
    const elapsedDuration = Date.now() - this.startTime;
    const shouldWarn = elapsedDuration > (this.sessionDuration - this.warningDuration);

    if (shouldWarn) {
      this.dialog.showModal();
      // TODO if no longer using reload, then must cancel this
      setTimeout(() => { window.location.href = encodeURI(`${window.location.origin}${this.logoutUrl}`); }, this.warningDuration);
    } else {
      setTimeout(() => { this.checkSessionLoop(); }, this._checkSessionFrequency);
    }
  }

  /**
   * - Begins infinite checkSessionLoop
   */
  execute () {
    this.checkSessionLoop();
  }
}

window.IGNITE['SessionRefreshModule'] = SessionRefreshModule;
