import FormValidator from './form-validator';
import LabelAnimation from './label-animation';
import { recordPageView } from './analytics';

class AddEmailModule {
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-primary');

    const formValidator = new FormValidator(form, submitButton);
    formValidator.setup();

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();

    recordPageView({
      pagefunction: 'registration',
      pagename: 'add_email'
    });
  }
}

window.IGNITE['AddEmailModule'] = AddEmailModule;
export default AddEmailModule;
