import FormValidator from './form-validator';

class RememberDeviceModule {
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-primary');

    const formValidator = new FormValidator(form, submitButton);
    formValidator.setup();
  }
}

window.IGNITE['RememberDeviceModule'] = RememberDeviceModule;
export default RememberDeviceModule;
