class MaskingHelperModule {
  execute (maskingButton, passwordField) {
    maskingButton.setAttribute('title', maskingButton.dataset['maskedTitle']);
    maskingButton.addEventListener('click', this.eventListener(maskingButton, passwordField));
  }

  eventListener (maskingButton, passwordField) {
    return (event) => {
      const inputDiv = maskingButton.closest('.input');

      if (inputDiv.classList.contains('masked')) {
        maskingButton.setAttribute('title', maskingButton.dataset['unmaskedTitle']);
        inputDiv.classList.remove('masked');
        passwordField.type = 'text';
      } else {
        maskingButton.setAttribute('title', maskingButton.dataset['maskedTitle']);
        inputDiv.classList.add('masked');
        passwordField.type = 'password';
      }

      event.preventDefault();
    };
  }
}

window.IGNITE['MaskingHelperModule'] = MaskingHelperModule;
export default MaskingHelperModule;
