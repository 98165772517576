import FormValidator from './form-validator';
import Cleave from 'cleave.js';
import LabelAnimation from './label-animation';
import { AnalyticsConstants, recordPageView } from './analytics';
import Fingerprint2 from 'fingerprintjs2';
import InputMaskModule from './mask-input';
import MaskingHelperModule from './masking-helper';

const pageFunctionMapping = {
  'forgot-username': 'forgot_username',
  'forgot-password': 'forgot_password',
  register: 'registration'
};

class RetrieveCustomerModule {
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-primary');
    const formValidator = new FormValidator(form, submitButton);

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();

    const maskingButton = document.querySelector('.masking-button');
    const passwordField = document.getElementsByClassName('js-password')[0];
    new MaskingHelperModule().execute(maskingButton, passwordField);

    new Cleave('input[name="date-of-birth"]', { // eslint-disable-line
      date: true,
      datePattern: ['m', 'd', 'Y']
    });

    new Cleave('input[name="ssn"]', { // eslint-disable-line
      delimiter: '-',
      blocks: [3, 2, 4],
      numericOnly: true
    });

    const dob = document.querySelector('#date-of-birth');
    const dobValidators = [() => RetrieveCustomerModule.ageLimitValidation(dob)];

    formValidator.registerCustomInputValidations(dob, dobValidators);
    formValidator.setup();

    // Retrieve customer used for forgot username, forgot password, and register user
    const pageFunction = pageFunctionMapping[form.getAttribute(AnalyticsConstants.ANALYTICS_PAGE_NAME_KEY)] || '';
    const flashMessage = document.getElementById('flash-message');
    const pageName = flashMessage ? flashMessage.getAttribute(AnalyticsConstants.ANALYTICS_PAGE_NAME_KEY) : 'start';

    recordPageView({
      pagefunction: pageFunction,
      pagename: pageName
    });

    const fingerprint = document.getElementById('fid');
    this.addFingerprint(fingerprint);
  }

  addFingerprint (fingerprint) {
    const doFingerprint = () => {
      Fingerprint2.get(components => {
        fingerprint.value = Fingerprint2.x64hash128(
          components
            .map(component => component.value)
            .join(''),
          31);
      });
    };

    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => doFingerprint());
    } else {
      setTimeout(() => {
        doFingerprint();
      }, 500);
    }
  }

  static ageLimitValidation (dobInputField) {
    const dateOfBirthParts = dobInputField.value.split('/');
    const dateOfBirth = new Date(`${dateOfBirthParts[2]}-${dateOfBirthParts[0]}-${dateOfBirthParts[1]}`);
    const age = RetrieveCustomerModule.calculateAge(dateOfBirth);
    const valid = age >= 18 && age <= 120;
    return { valid, customValidationMessage: 'Please enter a valid date of birth in the MM/DD/YYYY format' };
  }

  static calculateAge (dateOfBirth) {
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dateOfBirth.getFullYear();
    const month = currentDate.getMonth() - dateOfBirth.getMonth();

    if (month < 0 || (month === 0 && currentDate.getDate() < dateOfBirth.getDate())) {
      age--;
    }

    return age;
  }

  inputMasking () {
    new InputMaskModule().execute();
  }
}

window.IGNITE['RetrieveCustomerModule'] = RetrieveCustomerModule;
export default RetrieveCustomerModule;
