import FormValidator from './form-validator';
import LabelAnimation from './label-animation';
import PasswordValidationBinder from './password-validation-binder';
import { recordPageView } from './analytics';
import MaskingHelperModule from './masking-helper';

class CreateProfileModule {
  constructor (createProfileFlow) {
    this.pageSubFunction = createProfileFlow;
  }
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-primary');

    const formValidator = new FormValidator(form, submitButton);
    const passwordField = document.querySelector('#new-password');
    const confirmPasswordField = document.querySelector('#confirm-new-password');
    const usernameField = document.querySelector('#username');
    const usernameCheck = document.querySelector('#username-length-requirement');

    const passwordLengthChecklistItem = document.querySelector('#password-length-requirement');
    const passwordCaseChecklistItem = document.querySelector('#password-case-requirement');
    const passwordNumberChecklistItem = document.querySelector('#password-number-requirement');

    const passwordValidationBinder = new PasswordValidationBinder(
      formValidator,
      passwordField,
      confirmPasswordField,
      passwordLengthChecklistItem,
      passwordCaseChecklistItem,
      passwordNumberChecklistItem);

    passwordValidationBinder.bindValidators();

    formValidator.registerCustomInputValidations(usernameField, [() => this.usernameValidation(usernameField)]);
    usernameField.addEventListener('input', () => {
      const describeAria = document.getElementById('username');
      let ariaValue = 'username-requirement';
      const isUserNameValid = this.usernameValidation(usernameField).valid;

      if (isUserNameValid) {
        ariaValue = 'username-length-requirement';
      }

      FormValidator.toggleCheckmarkValidity(usernameCheck, isUserNameValid, describeAria, ariaValue);
    });

    formValidator.setup();

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();

    const passwordMaskingButton = document.querySelector('#new-password-masking-button');
    const confirmPasswordMaskingButton = document.querySelector('#confirm-new-password-masking-button');
    const maskingHelperModule = new MaskingHelperModule();
    maskingHelperModule.execute(passwordMaskingButton, passwordField);
    maskingHelperModule.execute(confirmPasswordMaskingButton, confirmPasswordField);

    recordPageView({
      pagefunction: 'registration',
      pagesubfunction: this.pageSubFunction,
      pagename: 'create_username_password'
    });
  }

  usernameValidation (usernameField) {
    const alphaNumericPattern = new RegExp('^[a-zA-Z0-9]{4,20}$');
    const valid = alphaNumericPattern.test(usernameField.value);

    if (!valid) {
      return { valid, customValidationMessage: 'Username must contain between 4 and 20 characters.' };
    }

    return { valid, customValidationMessage: '' };
  }
}

window.IGNITE['CreateProfileModule'] = CreateProfileModule;
export default CreateProfileModule;
