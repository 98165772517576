import formHelper from './form-helper';

class LabelAnimation {
  constructor (form) {
    this.FORM_ITEM_FOCUS_CLASS = 'focused';
    this.LABEL_POSITION_CLASS = 'top';

    this.inputs = formHelper.focusableInputs(form.elements);
  }

  setup () {
    this.inputs.forEach(input => {
      const formItem = input.closest('[data-form-element]');
      const label = formItem.querySelector('label');

      input.addEventListener('input', (e) => {
        if (e.target.value === '') {
          return false;
        }
        this.checkMoveLabelToTop(formItem, label);
      });
      input.addEventListener('animationstart', () => this.checkMoveLabelToTop(formItem, label));
      input.addEventListener('focusin', () => this.checkMoveLabelToTop(formItem, label));

      input.addEventListener('focusout', () => this.checkMoveLabelToInput(formItem, label, input));

      if (input.value !== '') {
        this.checkMoveLabelToTop(formItem, label);
      }
    });
  }

  checkMoveLabelToTop (formItem, label) {
    formItem.classList.add(this.FORM_ITEM_FOCUS_CLASS);

    if (label.dataset['shrink'] !== 'true') {
      label.classList.add(this.LABEL_POSITION_CLASS);
    }
  }

  checkMoveLabelToInput (formItem, label, input) {
    formItem.classList.remove(this.FORM_ITEM_FOCUS_CLASS);

    if (label.dataset['shrink'] !== 'true' && !input.value) {
      label.classList.remove(this.LABEL_POSITION_CLASS);
    }
  }
}

export default LabelAnimation;
