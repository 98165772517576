import FormValidator from './form-validator';
import LabelAnimation from './label-animation';
import { recordPageView, AnalyticsConstants } from './analytics';
import InputMaskModule from './mask-input';

class ValidateOtpModule {
  execute () {
    const form = document.querySelector('form');
    const submitButton = form.querySelector('.btn-primary');
    const secondaryButton = form.querySelector('.btn-secondary');

    const otpCodeInput = document.querySelector('input[name="otp-code"]');

    const formValidator = new FormValidator(form, submitButton, secondaryButton);
    const otpValidator = [() => ValidateOtpModule.validateOtpLength(otpCodeInput)];

    formValidator.registerCustomInputValidations(otpCodeInput, otpValidator);
    formValidator.setup();

    const labelAnimation = new LabelAnimation(form);
    labelAnimation.setup();

    otpCodeInput.addEventListener('input', () => {
      const nonNumbers = /[^0-9]/g;
      otpCodeInput.value = otpCodeInput.value.replace(nonNumbers, '');
    });

    const pageFunction = form.getAttribute(AnalyticsConstants.ANALYTICS_PAGE_NAME_KEY);
    const pageName = document.getElementById('flash-message') ? 'step2_codefailed' : 'step2';
    const attrName = form.getAttribute('phoneType');

    recordPageView({
      pagefunction: pageFunction,
      pagesubfunction: 'otp',
      pagename: pageName,
      attr: attrName
    });
  }

  inputMasking () {
    new InputMaskModule().execute();
  }

  otpCodeReader () {
    window.addEventListener('load', function () {
      document.getElementById('otp-code').addEventListener('input', function () {
        const otpCode = document.getElementById('otp-code').value.replace(/\D/g, '');
        const submitButton = document.querySelector('form').querySelector('.btn');
        if (otpCode.length === 4) {
          submitButton.disabled = false;
        }
      });
    });
  }

  static validateOtpLength (otpCodeInput) {
    const valid = otpCodeInput.value.length >= 4 && otpCodeInput.value.length <= 5;
    return { valid, customValidationMessage: '' };
  }
}

window.IGNITE['ValidateOtpModule'] = ValidateOtpModule;
export default ValidateOtpModule;
