import RadioGroup from './radio-group';

class GlobalInitializers {
  addButtonListeners (buttonLinks) {
    buttonLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        link.classList.add('disabled');
        link.classList.add('loading');

        setTimeout(() => {
          window.location.href = encodeURI(link.href);
        }, 100);
      });
    });
  }

  showFlashMessages (flashMessages) {
    flashMessages.forEach(flashMessage => {
      /*
      * There is a bug in VoiceOver on Safari that
      * causes VoiceOver to skip reading alerts if
      * they get triggered as soon as the page loads.
      * We delay the visibility of the messages by 250ms
      * to work around this issue and ensure messages
      * get read by VoiceOver.
      * */
      setTimeout(() => {
        flashMessage.classList.add('shown');
      }, 250);
    });
  }

  initializeRadioGroups (radioGroups) {
    radioGroups.forEach(radioGroup => {
      new RadioGroup(radioGroup).init();
    });
  }

  init () {
    document.addEventListener('DOMContentLoaded', () => {
      const buttonLinks = document.querySelectorAll('.btn-link');

      if (buttonLinks != null) {
        this.addButtonListeners(buttonLinks);
      }

      const radioGroups = document.querySelectorAll('[role="radiogroup"]');

      if (radioGroups != null) {
        this.initializeRadioGroups(radioGroups);
      }

      const flashMessages = document.querySelectorAll('.flash-message');

      if (flashMessages != null) {
        this.showFlashMessages(flashMessages);
      }
    });
  }
}

new GlobalInitializers().init();
